import {createSlice} from '@reduxjs/toolkit';
import assetsConfig from "../config/assets";
import appConfig from "../config/api";
import {setAgentId, setInternational, setTenantId} from "../utils/user";

export const defaultWebSite = {
    name : "空号检测平台",
    title: "空号检测平台"
}

const initialState = {
    website : defaultWebSite,
    international: window._CONFIG.international
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        appInit: (state, action) => {
            if(action.payload) {
                let {website, international = false} = action.payload
                if (website) {
                    if (website.logo) {
                        website.logo = appConfig.baseUrl + website.logo;
                    }
                    if (website.logoSmall) {
                        website.logoSmall = appConfig.baseUrl + website.logoSmall;
                    }
                    let host = window.location.host;
                    if(!host.startsWith("system") && host.indexOf("tenant") < 0){
                        document.title = website.title;
                    }
                    document.querySelector('meta[name="description"]').setAttribute('content',website.description);
                    state.website = website;
                    setTenantId(website.tenantId)
                    setAgentId(website.agentId)
                }
                state.international = international;
                setInternational(international ? 1 : 0)
            }
        }
    }
});

export const { appInit } = appSlice.actions;

export const selectWebsiteInfo = ( state ) => {
    return state.app?.website || defaultWebSite;
}

export const selectAppInfo = (state) => {
    return state.app;
}

export default appSlice.reducer;
