import Request from '../utils/request'

export const getUserList = (params) =>{
    return Request.get('/user/list', {params})
}

export const getUserInfoStatus = (params) =>{
    return Request.get('/user/info/status', {params})
}

export const searchUser = (params) => {
    return Request.get('/user/search', {params})
}

export const searchTenant = (params) => {
    return Request.get('/user/tenant', {params})
}

export const createUser = (data = {}, options = {}) => {
    return Request.post('/user/create', {data, ...options})
}

export const editUser = (data = {}, options = {}) => {
    return Request.post('/user/edit', {data, ...options})
}

export const deleteUser = (data = {}, options = {}) => {
    return Request.post('/user/delete', {data, ...options})
}

export const sevenAuthenticate = (data = {}, options = {}) => {
    return Request.post('/user/seven/day/authenticate', {data, ...options})
}

export const modPwd = (data = {}) => {
    return Request.post('/user/password/mod', {data})
}

export const modIp = (data = {}) => {
    return Request.post('/user/ip/mod', {data})
}

export const loadLoginUserInfo = () => {
    return Request.get('/user/info')
}

export const getUserProductBalance = (category, userId) => {
    return Request.get('/user/product/balance', {params : { category, userId }})
}

export const getHigherLevelBalance = (category, userId) => {
    return Request.get('/user/higher/level/balance', {params : { category, userId }})
}

export const getUserBalanceLog = (params) => {
   return Request.get('/user/balance/log', {params})
}

export const getUserReturnList = (params) => {
    return Request.get('/user/return/list', {params})
}

export const getUserReturnExcel = (params) => {
    return Request.get('/user/return/export', {params})
}

export const getUserReturnCounter = (params) => {
    return Request.get('/user/return/counter', {params})
}

export const getUserConsumeCounter = (params) => {
    return Request.get('/user/consume/counter', {params})
}

export const findUserAuthenticationList = (params = {}) => {
    return Request.get('/user/authentication/list', {params})
}

export const saveUserAuthentication = (data = {}, options = {}) => {
    return Request.post('/user/authentication/save', {data, ...options})
}

export const rejectUserAuthentication = (data = {}, options = {}) => {
    return Request.post('/user/authentication/reject', {data, ...options})
}

export const passUserAuthentication = (data = {}, options = {}) => {
    return Request.post('/user/authentication/pass', {data, ...options})
}

export const refreshApiSk = (data = {}, options = {}) => {
    return Request.post('/user/sk/refresh', {data, ...options})
}

export const toggleApiState = (data = {}, options = {}) => {
    return Request.post('/user/api/toggle', {data, ...options})
}

export const openProduct = (data = {}, options = {}) => {
    return Request.post('/user/product/open', {data, ...options})
}


export const modAgent = (data = {}) => {
    return Request.post('/user/agent/mod', {data})
}

export const getAllProductByAgent = () => {
    return Request.get('/user/product/all')
}

export const getAmountByAgent = () => {
    return Request.get('/user/amount/balance')
}

export const getAllLoginLog = (params) => {
    return Request.get('/user/login/log', {params})
}


export const sendGift = (data) => {
    return Request.post('/user/gift/send', {data})
}
