export const createSelectCallback = (styles) => ({ isActive }) => isActive ?  styles.selected : "";

export default [
    {to: "/login", component: "/login", text: "登陆"},
    {to: "/system/login", component: "/login", text: "登陆"},
    {to: "/agent/login", component: "/login", text: "登陆"},
    {to: "/register", component: "/register", text: "登陆"}
]

export const USER_DASHBOARD_LINK  = "/app/dashboard";
export const ADMIN_DASHBOARD_LINK  = "/admin/dashboard";
export const USER_LOGIN_LINK = "/login";
export const SYSTEM_LOGIN_LINK = "/system/login";
