import React, {useCallback, useEffect, useMemo, useState} from "react";
import {App, ConfigProvider} from "antd";
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import {useDispatch} from "react-redux";
import {useLocation, useRoutes} from "react-router-dom";
import {loadLoginUserInfo} from "./api/user";
import PageLoading from "./components/loading/PageLoading";
import {userInit} from "./model/user";
import {createRoutesWithLink, createRoutesWithNoLogin} from "./routes";
import {navigateToDashboard, navigateToLogin} from "./utils/navigator";
import render from "./utils/render";
import {getToken, isChina} from "./utils/user";
import {appInit} from "./model/app";
import {getAppInfo} from "./api/common";
import {adminLink, userLink} from "./layouts/sidebar/link";
import './i18n/config'
import './common.css';

function Application() {
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState()

    let dispatch = useDispatch();
    let location = useLocation();
    useEffect(()=>{
        //初始化系统
        init().then()
    }, [])

    const init = useCallback(async ()=>{
        const host = window.location.host;

        //初始化应用
        let appInfo = await getAppInfo(host);
        dispatch(appInit(appInfo))

        //初始化用户信息
        if(location.pathname.endsWith("/login") || location.pathname.endsWith("/login.html") || location.pathname.endsWith("/register") || location.pathname.endsWith("/register.html") || location.pathname.endsWith("/signup")){
           return;
        }

        let token = getToken();
        if(!token){
            if(host.startsWith("system")){
               navigateToLogin(false, false, true)
            }else if(host.indexOf("tenant.") >= 0){
               navigateToLogin(false, true, false)
            }
            navigateToLogin(true)
            return;
        }

        let userInfo = await loadLoginUserInfo();
        if(!userInfo || !userInfo.id){
            navigateToLogin();
            return;
        }

        //初始化用户信息
        dispatch(userInit(userInfo))
        setUserType(userInfo.type)
        setLoading(false)

        //如果没有跳转
        setTimeout(()=>{
            if(!location.pathname.startsWith("/admin") && !location.pathname.startsWith("/app")){
               navigateToDashboard(userInfo.type === "USER");
            }
        }, 500)

    },[])

    const memoRoutes = useMemo( ()=>{
        if(!userType){
            return createRoutesWithNoLogin()
        }
        return  createRoutesWithLink(userType === "USER" ? userLink : adminLink)
    }, [loading, userType])

    let routes = useRoutes(memoRoutes);
    return  loading ? <PageLoading /> : routes
}

render(
    <ConfigProvider locale={isChina() ? zhCN : enUS}>
        <App><Application /></App>
    </ConfigProvider>
)
