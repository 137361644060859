import {ConfigProvider} from "antd";
import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {store} from "../config/store";
import reportWebVitals from '../reportWebVitals';
import '../common.css'

const render = (entry) => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <ConfigProvider locale={locale}>
            <Provider store={store}>
                <BrowserRouter>
                    {entry}
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    );
}

export default render

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
