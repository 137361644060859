import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './en.json';
import translation_zh from './zh.json';
import {isCustomerUser} from "../utils/user";

const resources = {
    en: {
        translation: translation_en,
    },
    zh: {
        translation: translation_zh,
    },
};

let cacheLang = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
    resources,
    lng: cacheLang?.length > 0 && isCustomerUser() ? cacheLang : "zh",
    interpolation: {
       escapeValue: false,
    },
}).then();

export default i18n;
