import {createSlice} from '@reduxjs/toolkit';
import {navigateToLogin} from "../utils/navigator";
import {
    getUserType,
    removeAgentId,
    removeTenantId,
    removeToken,
    setTenantId,
    setUserType
} from "../utils/user";

const initialState = {
    userInfo : null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userInit: (state, action) => {
            let userType = action.payload.type;
            setUserType(userType)
            if(userType !== "USER"){
               window.localStorage.setItem("lang", "zh");
               let host = window.location.host;
               if(userType === "AGENT"){
                  document.title = "管理平台"
               }else if(userType === "ADMIN" || host.indexOf("tenant") >= 0){
                  document.title = "租户平台"
               }else if(userType === "SUPER_ADMIN" || host.startsWith("system")){
                  document.title = "总平台"
               }
            }
            if(action.payload.tenantId){
               setTenantId(action.payload.tenantId)
            }
            state.userInfo = action.payload;
        },
        userLogout : (state) => {
            removeToken();
            removeTenantId();
            removeAgentId();
            let userType = getUserType();
            if(userType === 1){
               window.location.href = "/tenant/login";
            }else if(userType === 2){
               window.location.href = "/agent/login";
            } else if(userType === 3){
               window.location.href = "/login";
            }else if(userType === 9){
               window.location.href = "/system/login";
            }else{
               navigateToLogin()
            }
        },
        userUpdate : (state, action) => {
            let { payload } = action;
            if(state.userInfo){
               state.userInfo = {...state.userInfo, ...payload}
            }
        }
    }
});

export const { userInit, userLogout, userUpdate } = userSlice.actions;

export const selectUserInfo = (state) => {
    if(!state.user){
        return undefined;
    }
    let userInfo = {...state.user.userInfo};
    return userInfo?.id ? userInfo : undefined;
}



export default userSlice.reducer;
