import Request from '../utils/request'

export const allProductCategory = (params) =>{
    return Request.get('/product/category/all', {params})
}

export const allDetectionState = (params) =>{
    return Request.get('/detection/state/all', {params})
}

export const allDetectionStateByProduct = (params) =>{
    return Request.get('/detection/state/product', {params})
}


export const internationalProductCategory = (params) =>{
    return Request.get('/product/category/international', {params})
}

export const getAppInfo = (host) => {
    return Request.get("/app/init", {params: {host}})
}

export const getCountryCode = () => {
    return Request.get("/country/code");
}


export const getCaptcha = () => {
    return Request.get("/captcha");
}

export const uploadErrorReport = (data) => {
    return Request.post("/upload/error/report", {data});
}
